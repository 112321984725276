import React from "react";
import { animated, useSpring } from "react-spring";

export function Header() {
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.header
      style={fadeIn}
      className="uk-text-center header uk-margin-small-top uk-margin-small-bottom"
    >
      <span
        onClick={() => window.location.reload(false)}
        style={{ cursor: "pointer", margin: "0 auto" }}
      >
        <h1
          style={{
            width: "fit-content",
            padding: "12px",
            fontSize: "100px",
            display: "inline",
            fontFamily: "'Qwigley', cursive",
            lineHeight: "100px"
          }}
        >
          m
        </h1>
        <h1
          style={{
            display: "inline",
            position: "relative",
            paddingRight: "12px",
            left: "-12px",
            fontWeight: 600,
            fontSize: "50px",
            fontFamily: "'Qwigley', cursive"
          }}
        >
          ax
        </h1>
      </span>
    </animated.header>
  );
}
