import React from 'react';
import { useState } from 'react';
import useInfiniteScroll from './useInfiniteScroll';
import { Day } from './day';
import { generateVersion, paddedDay } from './utils';

export function Project365() {
  const daysSinceBirthday = 367;
  const numToFetchWhenScrolling = 9;

  const [listItems, setListItems] = useState(
    Array.from(Array(daysSinceBirthday).keys(), n => n)
      .reverse()
      .slice(0, numToFetchWhenScrolling)
  );

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  const [haveTaggedImages, setHaveTaggedImages] = useState(false);
  const [taggedMap, setTaggedMap]: any = useState({});
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  function fetchMoreListItems() {
    if (hasReachedEnd) {
      return;
    }
    if (listItems[listItems.length - 1] <= 0) {
      setHasReachedEnd(true);
      return;
    }
    setListItems(prevState => [
      ...prevState,
      ...Array.from(
        Array(daysSinceBirthday).keys(),
        n => n - (prevState.length)
      )
        .reverse()
        .slice(0, numToFetchWhenScrolling),
    ]);
    setIsFetching(false);
  }

  function fetchTaggedImages() {
    if (Object.keys(taggedMap).length > 0) {
      return;
    }

    const tagMap: any = {};
    fetch(
      `https://res.cloudinary.com/mybabysage/image/list/${generateVersion()}/max.json`
    )
      .then(resp => resp.json())
      .then(res => {
        res.resources.forEach((obj: any) => {
          tagMap[obj.public_id] = obj;
        });

        setTaggedMap(tagMap);
        setHaveTaggedImages(true);
      });
  }

  fetchTaggedImages();

  if (!haveTaggedImages) {
    return null;
  }

  const realDays = listItems.map(item => {
    return (taggedMap[paddedDay(item)]) && item
  }).filter(item => typeof item === 'number');

  if (realDays.length < numToFetchWhenScrolling) {
    fetchMoreListItems();
  }

  return (
    <div
      className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m"
      uk-grid="true"
    >
      {realDays.map(day => (
        <Day day={day} key={day} />
      ))}
      {isFetching && !hasReachedEnd && (
        <img
          src="/spinner.svg"
          className="uk-margin-auto uk-margin-medium"
          style={{ height: '50px', width: '50px', display: 'block' }}
          alt="spinner"
        />
      )}
    </div>
  );
}
