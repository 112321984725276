import React, { useState } from 'react';
import { imageUrl, calculateDay } from './utils';
import { SyntheticEvent } from 'react';
import { useSpring, animated } from 'react-spring';

interface DayProps {
  day: number;
}

export function Day(props: DayProps) {
  const [hasImageLoaded, setHasImageLoaded] = useState(false);
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

  function handleLoad(evt: SyntheticEvent<HTMLImageElement>) {
    evt.currentTarget.focus();
  }

  function handleImageLoad(event: Event) {
    setHasImageLoaded(true);
  }

  function loadImage() {
    const img = new Image();
    img.src = imageUrl(props.day);
    img.onload = handleImageLoad;
  }

  loadImage();

  if (!hasImageLoaded) {
    return (
      <animated.div style={fadeIn} className="uk-inline" key={props.day}>
        <img
          src="https://placeholder.pics/svg/1200x1200/DEDEDE/DEDEDE/%20"
          alt={`Day ${props.day}`}
          style={{ borderRadius: '12px' }}
          onLoad={handleLoad}
        />
      </animated.div>
    );
  }

  return (
    <animated.div style={fadeIn} className="uk-inline" key={props.day}>
      <img
        src={imageUrl(props.day)}
        alt={`Day ${props.day}`}
        style={{ borderRadius: '12px' }}
        onLoad={handleLoad}
      />
      <div
        style={{
          borderRadius: '0 0 12px 0',
          background: `linear-gradient(135deg, rgba(50,50,50,0) 55%, rgba(50,50,50,1) 100%)`,
          position: 'absolute',
          bottom: 0,
          right: 0,
          height: 250,
          width: 250,
        }}
      ></div>
      <div
        className="uk-overlay uk-light uk-position-bottom-right"
        style={{ padding: '15px 20px' }}
      >
        <h1 className="uk-margin-remove uk-text-right">{props.day}</h1>
        <p
          className="uk-margin-remove uk-text-small uk-text-right"
        >
          {calculateDay(props.day)}
        </p>
      </div>
    </animated.div>
  );
}
