import moment from 'moment';

const birthday = '2019-11-06';

const overrides: { [key: string]: string } = {
  // '006': '006-1'
}

export function calculateDaysSinceBirthday() {
  return moment().diff(moment(birthday), 'days') + 1;
}

export function pad(value: number, size: number) {
  var s = String(value);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
}

export function paddedDay(day: number) {
  return pad(day, 3);
}

export function imageFile(day: number) {
  const filename = paddedDay(day);
  return overrides[filename] || filename;
}

export function imageUrl(day: number) {
  return `https://res.cloudinary.com/mybabysage/image/upload/w_1200,ar_1:1,c_fill,g_auto,q_auto:best/${imageFile(
    day
  )}.jpg`;
}

export function calculateDay(day: number) {
  var output = moment(birthday)
    .add(day, 'days')
    .format('M/D');
  return output;
}

export function generateVersion() {
  return `v${Math.round(Date.now() / 1000)}`;
}
