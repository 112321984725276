import { useState, useEffect } from 'react';

// TODO: fix any type
const useInfiniteScroll: any = (callback: Function) => {
  const [isFetching, setIsFetching] = useState(false);
  const offset = 400;

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop + offset <
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }

    setIsFetching(true);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (!isFetching) return;
    callback();
  });

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
